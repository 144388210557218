import { userInfoSync } from 'hub-http/userInfo';
export function getIsAiCopilotEnabled() {
  return Boolean(document.querySelector('iframe[name="nav-components:chatspot-sidebar"]'));
}
export function getIsUngatedForAiCopilot() {
  try {
    // userInfoSync can throw an error if userInfo hasn't already been called, which will be the case for internal apps
    const userInfo = userInfoSync();
    return userInfo.gates.includes('Chatspot:Widget:Beta');
  } catch (e) {
    return false;
  }
}