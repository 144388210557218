// @ts-expect-error untyped file
import { publishVisitorMessage } from '../pubsub/actions/publishVisitorMessage';
import { trackInteraction } from '../usage-tracking/actions/trackInteraction';
// @ts-expect-error untyped file
import { toggleOpen } from './WidgetActions';
export function selectQuickReplyOptionOutsideChat(text, quickReply) {
  return dispatch => {
    const selectedQuickReplyOption = {
      '@type': 'QUICK_REPLIES',
      quickReplies: [quickReply.toJS()],
      allowMultiSelect: false,
      allowUserInput: true
    };
    dispatch(publishVisitorMessage({
      text,
      quickReply: selectedQuickReplyOption
    })).then(() => {
      dispatch(toggleOpen({
        isOpened: true,
        isUser: true
      }));
      dispatch(trackInteraction('widget-interaction', {
        action: 'clicked quick reply option outside chat'
      }));
    }).catch(() => {
      throw new Error('selectQuickReplyOptionOutsideChat failed');
    });
  };
}