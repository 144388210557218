import { lWindow } from 'usage-tracker-core/common/helpers';
export const createHistory = () => {
  const hasWindowAndHistory = typeof lWindow === 'object' && typeof lWindow.history === 'object';
  const getCurrentState = () => hasWindowAndHistory && lWindow.history.state && lWindow.history.state.state || {};
  return {
    get location() {
      if (hasWindowAndHistory) {
        return {
          pathname: lWindow.location.pathname,
          search: lWindow.location.search,
          hash: lWindow.location.hash,
          state: getCurrentState(),
          key: getCurrentState().key || ''
        };
      }
      return {
        pathname: '',
        search: '',
        hash: '',
        state: getCurrentState(),
        key: getCurrentState().key || ''
      };
    },
    replace: (location, state = {}) => {
      if (hasWindowAndHistory) {
        lWindow.history.replaceState(Object.assign({}, state), '', typeof location === 'string' ? `${lWindow.location.origin}${location}` : location.toString());
      }
    },
    push: (location, state = {}) => {
      if (hasWindowAndHistory) {
        lWindow.history.pushState(Object.assign({}, state), '', typeof location === 'string' ? `${lWindow.location.origin}${location}` : location.toString());
      }
    }
  };
};